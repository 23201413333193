import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";

import { DIALOG_NAME, ROUTE_PATH, SERIES_TYPE } from "src/constants";
import { SeriesNavIcon } from "src/components/Icons";

export const useSections = ({ seriesItems, portalItem, setCurrentDialog }) => useMemo(
  () => {
    if (!portalItem || !seriesItems) return [];
    const { id: portalId } = portalItem;

    const noSeries = seriesItems.length === 0;
    const noShares = seriesItems.filter(item => item.type === SERIES_TYPE.SHARES).length === 0;
    const noWarrants = seriesItems.filter(item => item.type === SERIES_TYPE.WARRANT).length === 0;
    const isMasterFund = get(portalItem, "attributes.isMasterFund", false);
    const isVentureFund = get(portalItem, "attributes.isVentureFund", false);

    const  seriesItemsSection  = sortBy(seriesItems, "name").map((seriesItem) => {
      if (seriesItem.type === SERIES_TYPE.FUNDRAISING)  {
        return {
          title : `${seriesItem.self_accreditate ? "🔒" : ""}${seriesItem.name}`,
          path  : `/p/${portalId}/${ROUTE_PATH.SERIES}/${seriesItem.id}`,
        };
      }

      if (seriesItem.type === SERIES_TYPE.VERIFICATION)  {
        return {
          title : `${seriesItem.name}`,
          path  : `/p/${portalId}/${ROUTE_PATH.SERIES}/${seriesItem.id}`,
        };
      }
      return {};
    });

    const warrantsItemsSection = sortBy(seriesItems, "name")
      .map((seriesItem) => {
        if (seriesItem.type === SERIES_TYPE.WARRANT) {
          return {
            title : `${seriesItem.self_accreditate ? "🔒" : ""}${seriesItem.name}`,
            path  : `/p/${portalId}/${ROUTE_PATH.WARRANT}/${seriesItem.id}`,
          };
        }
        return null;
      })
      .filter(item => item !== null);

    const grantsItemsSection = sortBy(seriesItems, "name")
      .map((seriesItem) => {
        if (seriesItem.type === SERIES_TYPE.GRANT) {
          return {
            title : `${seriesItem.self_accreditate ? "🔒" : ""}${seriesItem.name}`,
            path  : `/p/${portalId}/${ROUTE_PATH.GRANT}/${seriesItem.id}`,
          };
        }
        return null;
      })
      .filter(item => item !== null);

    const noGrants = grantsItemsSection.length === 0;

    if (noSeries) {
      seriesItemsSection.push({
        title  : "New Series",
        action : () => setCurrentDialog(DIALOG_NAME.SERIES_TYPE, {
          type: "new_series", portalItem
        }) });
    }

    const sharesItemsSection = sortBy(seriesItems, "name")
      .map((seriesItem) => {
        if (seriesItem.type === SERIES_TYPE.SHARES)  {
          return {
            title : `${seriesItem.self_accreditate ? "🔒" : ""}${seriesItem.name}`,
            path  : `/p/${portalId}/${ROUTE_PATH.SHARES}/${seriesItem.id}`,
          };
        }
        return null;
      })
      .filter(item => item !== null);

    if (noShares) {
      sharesItemsSection.push({
        title  : "Import cap table",
        action : () => setCurrentDialog(DIALOG_NAME.IMPORT_CAP_TABLE, {
          type: "IMPORT_CAP_TABLE", portalItem
        }) });
    }

    if (isMasterFund || isVentureFund) { // The portal is used to create SPVs
      return [
        {
          items: [
            {
              title : "Dashboard",
              path  : `/p/${portalId}/${ROUTE_PATH.DASHBOARD}`,
              icon  : <RocketLaunchIcon />,
            },
            {
              title : "Stakeholders",
              path  : `/p/${portalId}/${ROUTE_PATH.STAKEHOLDERS}`,
              icon  : <PeopleAltOutlinedIcon />,
            },
            {
              title : "Investments",
              path  : `/p/${portalId}/${ROUTE_PATH.FUNDRAISING.INVESTMENTS}`,
              icon  : <PaidOutlinedIcon />,
            },
            {
              title : isMasterFund ? "SPVs" : "Funds",
              path  : `/p/${portalId}/${ROUTE_PATH.SERIES}`,
              icon  : <SeriesNavIcon />,
              items : seriesItemsSection,
            },
            {
              title : "Data Room",
              path  : `/p/${portalId}/${ROUTE_PATH.DATA_ROOM}`,
              icon  : <DatasetOutlinedIcon />,
            },
          ],
        },
      ];
    }
    return [
      {
        items: [
          {
            title : "Dashboard",
            path  : `/p/${portalId}/${ROUTE_PATH.DASHBOARD}`,
            icon  : <RocketLaunchIcon />,
          },
          {
            title : "Stakeholders",
            path  : `/p/${portalId}/${ROUTE_PATH.STAKEHOLDERS}`,
            icon  : <PeopleAltOutlinedIcon />,
          },
          {
            title : "Investments",
            path  : `/p/${portalId}/${ROUTE_PATH.FUNDRAISING.INVESTMENTS}`,
            icon  : <PaidOutlinedIcon />,
          },
          {
            title : "Cap Table",
            path  : `/p/${portalId}/${ROUTE_PATH.CAPTABLE.SUMMARY}`,
            icon  : <PieChartOutlineIcon />,
          },
          {
            title    : "Shares",
            path     : `/p/${portalId}/${ROUTE_PATH.SHARES}`,
            icon     : <SeriesNavIcon />,
            items    : sharesItemsSection,
            disabled : noShares
          },
          {
            title    : "SAFEs",
            path     : `/p/${portalId}/${ROUTE_PATH.SERIES}`,
            icon     : <SeriesNavIcon />,
            items    : seriesItemsSection,
            disabled : noSeries
          },
          {
            title    : "Warrants",
            path     : `/p/${portalId}/${ROUTE_PATH.WARRANT}`,
            icon     : <SeriesNavIcon />,
            items    : warrantsItemsSection,
            disabled : noWarrants
          },
          {
            title    : "Grants",
            path     : `/p/${portalId}/${ROUTE_PATH.GRANTS.EQUITY_GRANTS}`,
            icon     : <WorkspacePremiumOutlinedIcon />,
            items    : grantsItemsSection,
            disabled : noGrants
          },
          {
            title : "Tax & Compliance",
            path  : `/p/${portalId}/${ROUTE_PATH.TAX.INDEX}`,
            icon  : <AssuredWorkloadOutlinedIcon />,
            items : [
              {
                title : "Filings",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.FILINGS}`,
              },
              {
                title : "Risk factors",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.RISK_FACTORS}`,
              },
              {
                title : "State fees",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.STATE_FEES}`,
              },
            ],
          },
          {
            title : "Data Room",
            path  : `/p/${portalId}/${ROUTE_PATH.DATA_ROOM}`,
            icon  : <DatasetOutlinedIcon />,
          },
        ],
      },
    ];
  },
  [seriesItems, portalItem, setCurrentDialog]
);
